import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Cta from "../components/cta";

const LodgingPage = () => (
  <Layout backgroundColor="#FBFBEF">
    <Seo title="SF Culture" />

    <h1>SF Culture</h1>

    {/* <p>To get you excited about visiting San Francisco we've put together some examples of SF culture. We often will watch movies in thematic blocks. </p> */}

    <p>
      Anticipation is half the fun of travel. We love a good theme and there's a
      ton of culture about San Francisco out there. We’ve listed some of our
      favorites.{" "}
    </p>

    <h3>Movies:</h3>
    <p>
      <strong>Star Trek IV: The Voyage Home (1986)</strong>
      <br />
      The one with the whales, directed by Leonard Nimoy. Monica’s favorite,
      feel-good SF movie. Memorable line: “Double dumb ass on you!”
    </p>

    <p>
      <strong>The Last Black Man in San Francisco (2019)</strong>
      <br />A drama of gentrification, with excellent performances. The first 10
      minutes (mostly tracking the protagonists on a skateboard) are some of the
      finest filmmaking out there.
    </p>

    <p>
      <strong>Milk (2008)</strong>
      <br />A story of the first openly gay person to be elected to public
      office in California. A glimpse of San Francisco long before the tech boom
      and the fights that needed to happen for queer people to be able to live
      freely and openly.
    </p>

    <p>
      <strong>Vertigo (1958)</strong>
      <br />
      Hitchcock’s classic and one of the best movies ever made. Watch it, then
      take yourself on a{" "}
      <a href="https://www.tripsavvy.com/vertigo-movie-tour-of-san-francisco-1476203">
        walking tour
      </a>{" "}
      of the film locations.
    </p>

    <p>
      <strong>Sneakers (1992)</strong>
      <br />
      Robert Redford, Dan Aykroyd, Ben Kingsley, River Phoenix, Sidney Poitier,
      David Strathairn (and James Earl Jones). What, you haven’t seen it?! This
      movie about spy craft and “about who controls the information” is still
      remarkably relevant. Memorable line: “Too many secrets.”
    </p>

    <p>
      <strong>The Rock (1996)</strong>
      <br />
      Sean Connery, Nick Cage, and Ed Harris. It’s an action blockbuster set at
      Alcatraz with a very SF car chase. It holds up!
    </p>

    <p>
      <strong>The Wedding Planner (1999)</strong> <br />
      Sweet rom com starring J. Lo and Matthew McConaughey, with plenty of fun{" "}
      <a href="www.filminamerica.com/Movies/TheWeddingPlanner/">SF sightings</a>
      .
    </p>

    <p>
      <strong>Pirates of Silicon Valley (1999)</strong>
      <br />A dramatization of the history of tech featuring Noah Wyle as Steve
      Jobs and Anthony Michael Hall as Bill Gates.
    </p>

    <ul>
      <h4>Podcasts:</h4>
      <li>Bay Curious</li>
      <li>Total SF</li>
    </ul>

    <ul>
      <h4>Books:</h4>
      <li>
        <em>Uncanny Valley</em> by Anna Wiener
      </li>
    </ul>

    <h3 style={{ marginTop: "3em" }}>Weather in the Bay</h3>
    <p>
      September and October is SF summer. That being said, it doesn’t tend to
      get much above 70º, with cooler mornings and evenings. Layers are
      advisable, as temperatures can vary a lot between neighborhoods (the
      Mission, Oakland, and Berkeley are all a lot warmer than the Sunset, as
      much as by 10 degrees). Unfortunately, October and November are also
      historically considered wildfire season, which can make for nasty air
      quality.{" "}
    </p>

    <h3>Crime</h3>
    <p>
      Although SF is not a high-crime city, car break-ins are common, including
      in Golden Gate Park. It’s best practice to remove all visible belongings
      from your vehicle.
    </p>
    <Cta url="/registry" text="Check out the registry" />
  </Layout>
);

export default LodgingPage;
